import "core-js/modules/es6.regexp.split";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { SeaRuleList, GetConditionList, GetRegressionStructures, SeaRuleSet, SeaRuleRead, SeaRuleDel } from '@/api/ceshi_xiugai/popularize';
export default {
  data: function data() {
    return {
      title: '数据回收规则',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      value: '',
      form: {
        id: '',
        RuleName: '',
        WatersType: '',
        Department: '',
        MethodVal: ''
      },
      rules: {
        RuleName: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        WatersType: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        Department: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }],
        MethodVal: [{
          required: true,
          message: '必填',
          trigger: 'blur'
        }]
      },
      quantity: 1,
      QuantityKey: 1002,
      titles: '',
      screate_time: '',
      ecreate_time: '',
      InternationalVal: '',
      DepartmentArr: [],
      WatersTypeArr: [{
        value: '1',
        label: '部门公海'
      }, {
        value: '2',
        label: '部门二级公海'
      }],
      MethodArr: [{
        value: '1',
        label: '自动回收'
      }],
      options: [{
        value: 'or',
        label: '满足以下任意条件'
      }, {
        value: 'and',
        label: '满足以下全部条件'
      }],
      optionse: [],
      optionsIf: [{
        value: 'and',
        label: '且（and）一下条件组'
      }, {
        value: 'or',
        label: '或（or）一下条件组'
      }],
      vals: '新增数据回收规则',
      dialogWeight: false,
      tableData: [],
      Apply: [],
      ApplyVal: '',
      createtime: '',
      RecyclingRules: [{
        condition_type_big_group: 'and',
        condition_type_small_group: '',
        conditions: [{
          condition: [],
          fourth_condition: 0
        }]
      }],
      asd: '',
      idse: '',
      repeats: [],
      RepeatR: false,
      Recy: [],
      sjjsh: [],
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      asdasd: false
      // 系统教程
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    GetList: function GetList(page, pagesize) {
      var _this = this;
      SeaRuleList({
        page: String(page),
        pagesize: String(pagesize),
        title: this.titles,
        sea_type: this.InternationalVal.toString(),
        sid: this.ApplyVal.toString(),
        create_stime: this.screate_time,
        create_etime: this.ecreate_time
      }).then(function (respomse) {
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
      });
    },
    prevent: function prevent(e, index, key) {
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      this.$message.closeAll();
      // 验证是否是纯数字
      var isNumber = /^\d*$/.test(e.target.value);
      // 过滤非数字
      e.target.value = e.target.value.replace(/\D/g, '');
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数');
        e.target.value = 0;
        this.RecyclingRules[index].conditions[key].fourth_condition = 0;
      } else if (!isNumber || e.target.value <= 0 || e.target.value > 1000) {
        this.$message.warning('请填写<=1000的正整数');
        e.target.value = 0;
        this.RecyclingRules[index].conditions[key].fourth_condition = 0;
      }
    },
    handleFocus: function handleFocus(val, index, key) {
      if (val.length == 3) {
        this.idse = Number(String(val[2]) + String(index));
      } else if (val.length == 2) {
        this.idse = Number(String(val[1]) + String(index));
      }
    },
    handleChange: function handleChange(val, index, key) {
      if (val[0] == 1002) {
        this.RecyclingRules[index].conditions[key].fourth_condition = 0;
      }
      var Arrk = [];
      this.RecyclingRules.forEach(function (item, i) {
        item.conditions.forEach(function (event) {
          if (event.condition.length == 3) {
            Arrk.push(Number(String(event.condition[2]) + String(i)));
          } else if (event.condition.length == 2) {
            Arrk.push(Number(String(event.condition[1]) + String(i)));
          }
        });
      });
      this.repeats = Arrk;
      if (new Set(this.repeats).size !== this.repeats.length) {
        this.repeats = _toConsumableArray(new Set(this.repeats));
        this.$message({
          message: '禁止重复选择',
          type: 'warning'
        });
        this.RecyclingRules[index].conditions[key].condition = [];
      }
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this2.GetList(_this2.query.page, _this2.query.pagesize);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    submitEnterTime: function submitEnterTime(item) {
      //订单提交时间
      if (this.createtime == null) {
        this.screate_time = '';
        this.ecreate_time = '';
      } else {
        this.screate_time = this.createtime[0].toString().substring(0, 10);
        this.ecreate_time = this.createtime[1].toString().substring(0, 10);
      }
    },
    NewRule: function NewRule() {
      var _this4 = this;
      GetConditionList().then(function (res) {
        _this4.dialogWeight = true;
        _this4.optionse = res.data;
      });
      var id = this.form.id == '' ? 0 : this.form.id;
      GetRegressionStructures({
        id: id
      }).then(function (res) {
        _this4.DepartmentArr = res.data;
      });
    },
    ApplyFocus: function ApplyFocus() {
      var _this5 = this;
      GetRegressionStructures({
        is_show_all: 1
      }).then(function (res) {
        _this5.Apply = res.data;
      });
    },
    objOfPropertyToArr: function objOfPropertyToArr(object) {
      var arr = [];
      var i = 0;
      for (var item in object) {
        arr[i] = item;
        i++;
      }
      return arr;
    },
    Increasing: function Increasing() {
      var condition = {
        condition_type_big_group: 'and',
        condition_type_small_group: '',
        conditions: [{
          condition: [],
          fourth_condition: 0
        }]
      };
      this.RecyclingRules.push(condition);
    },
    classification: function classification(index) {
      var category = {
        condition: [],
        fourth_condition: 0
      };
      this.RecyclingRules[index].conditions.push(category);
    },
    condition: function condition(index) {
      if (index !== 0) {
        this.RecyclingRules.splice(index, 1);
      } else {}
      this.RecyclingRules.map(function (item, key) {
        if (key == 0) {
          item.condition_type_big_group = 'and';
        }
      });
    },
    DeleteCondition: function DeleteCondition(index, key) {
      var as = this.RecyclingRules[index].conditions[key].condition;
      if (as.length == 3) {
        this.asd = Number(String(as[2]) + String(index));
      } else if (as.length == 2) {
        this.asd = Number(String(as[1]) + String(index));
      }
      var subscript = this.indexOf(this.repeats, this.asd);
      this.repeats.splice(subscript, 1);
      this.RecyclingRules[index].conditions.splice(key, 1);
    },
    indexOf: function indexOf(arr, item) {
      if (Array.prototype.indexOf) {
        return arr.indexOf(item);
      } else {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] === item) {
            return i;
          }
        }
      }
      return -1;
    },
    TionRules: function TionRules() {
      var _this6 = this;
      this.RecyclingRules.map(function (res) {
        res.conditions.map(function (item) {
          item.condition = item.condition.toString();
        });
      });
      var aad = JSON.stringify(this.RecyclingRules);
      var then = this.form;
      this.RecyclingRules.forEach(function (n, index) {
        if (n.condition_type_small_group == '') {
          _this6.Recy.push(n);
        } else {
          _this6.Recy = [];
        }
      });
      this.RecyclingRules.forEach(function (n, k) {
        for (var i = 0; i < n.conditions.length; i++) {
          if (n.conditions[i].condition == '') {
            _this6.sjjsh.push(n.conditions[i]);
          } else {
            _this6.sjjsh = [];
          }
        }
      });
      if (this.Recy.length > 0) {
        this.$message({
          message: '请您填写完整~',
          type: 'warning'
        });
      } else {
        if (this.sjjsh.length > 0) {
          this.$message({
            message: '请您填写完整~',
            type: 'warning'
          });
        } else {
          SeaRuleSet({
            id: then.id,
            title: then.RuleName,
            sea_type: then.WatersType,
            sids: then.Department.toString(),
            regression_type: then.MethodVal,
            conditions: aad.toString()
          }).then(function (res) {
            _this6.ResetVar();
            _this6.dialogWeight = false;
            _this6.GetList(_this6.query.page, _this6.query.pagesize);
          }).catch(function () {
            _this6.RecyclingRules.map(function (n, k) {
              for (var i = 0; i < n.conditions.length; i++) {
                n.conditions[i].condition = n.conditions[i].condition.split(',').map(function (item) {
                  return Number(item);
                });
              }
            });
          });
        }
      }
    },
    ConfirmationRules: function ConfirmationRules(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this7.TionRules();
        } else {
          _this7.$message({
            message: '请完善信息！',
            type: 'warning',
            duration: 2000
          });
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.dialogWeight = false;
      this.ResetVar();
      this.$refs[formName].resetFields();
    },
    cancel: function cancel(formName) {
      this.ResetVar();
      this.$refs[formName].resetFields();
    },
    ResetVar: function ResetVar() {
      this.form = {
        id: '',
        RuleName: '',
        WatersType: '',
        Department: '',
        MethodVal: ''
      };
      this.RecyclingRules = [{
        condition_type_big_group: 'and',
        condition_type_small_group: '',
        conditions: [{
          condition: [],
          fourth_condition: 0
        }]
      }];
      this.repeats = [];
    },
    EditRule: function EditRule(id) {
      var _this8 = this;
      this.form.id = id;
      this.dialogWeight = true;
      SeaRuleRead({
        id: id
      }).then(function (res) {
        _this8.NewRule();
        _this8.form.RuleName = res.data.title, _this8.form.WatersType = String(res.data.sea_type), _this8.form.Department = res.data.sids.split(',').map(function (item) {
          return Number(item);
        }), _this8.form.MethodVal = res.data.regression_type.toString();
        var Arr = JSON.parse(res.data.conditions);
        Arr.map(function (res, index) {
          res.conditions.map(function (item, key) {
            item.condition = item.condition.split(',').map(function (item) {
              return Number(item);
            });
            if (item.condition.length == 3) {
              _this8.repeats.push(Number(String(item.condition[2]) + String(index)));
            } else if (item.condition.length == 2) {
              _this8.repeats.push(Number(String(item.condition[1]) + String(index)));
            }
          });
        });
        _this8.RecyclingRules = Arr;
      });
    },
    DeleteRule: function DeleteRule(id, name) {
      var _this9 = this;
      this.$confirm('规则删除后不可恢复，确认删除【' + name + '】？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(function () {
        SeaRuleDel({
          id: id
        }).then(function (res) {
          _this9.$notify({
            title: '提示',
            message: '删除成功',
            type: 'success',
            duration: 3000
          });
          _this9.GetList(_this9.query.page, _this9.query.pagesize);
        });
      }).catch(function () {});
    },
    QueryClick: function QueryClick() {
      this.query.page = 1;
      this.GetList(this.query.page, this.query.pagesize);
    }
  }
};